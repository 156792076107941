import React, { useState } from 'react';

import './App.css'
import '@blueprintjs/core/lib/css/blueprint.css'

import SentenceAnnotator from '@botsquad/sentence-annotator';
import { Sentence } from '@botsquad/sentence-annotator/dist/types/lib/annotator';

const INITIAL = {
  data: [
    {
      text: 'Hello, my ',
    },
    {
      text: 'nickname',
      name: 'type',
      entity: '@name-type',
    },
    {
      text: ' is ',
    },
    {
      text: 'pete',
      name: 'nick-name',
      entity: '@nick-name',
    },
    {
      text: '. ',
    }
  ],
};

const App: React.FC = () => {
  const [sentence, setSentence] = useState<Sentence>(INITIAL)

  return (
    <div>
      <div className="container">
        <h1>Sentence annotator demo</h1>

        <p>This is a demonstration of the <a href="https://www.npmjs.com/package/@botsquad/sentence-annotator">@botsquad/sentence-annotator</a> component.</p>

        <div className="bp3-input">
          <SentenceAnnotator value={sentence} onChange={setSentence} />
        </div>

        <h2>Result</h2>
        <code>
          <pre>{JSON.stringify(sentence, null, 2)}</pre>
        </code>
      </div>
    </div>
  );
}

export default App;
